* {
  margin: 0;
  padding: 0;
}



/* start center coin on Landing */
.center-image{
  width: 100%;
 }

.center-image img{
  max-width:100%;
  max-height:100%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}

/* End center coin on Landing */

.bio-container {
  background-image: url("./images/island-atz.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.landingHero {
    background-image: url("./images/statue-atz.jpg") !important;
    background-repeat: no-repeat !important;
    background-size: cover;
}

.dark-container {
  background-image: url("./images/hall-atz.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.contact-container {
  background-image: url("./images/waterfall-atz.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

.flyin-container {
  background-image: url("./images/building-atz.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}



.phlokk_icon {
  font-size: 35px;
}


/* Start Hero Container  */
.connectContainer {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -999;
}
/* End Hero Container */

.contactTxt {
  font-family: Mosthat;
  font-size: 40px;
  color: white;
  text-align: center;
}

/* start Button */
.subscribeBtnView {
  margin-top: 50px;
}
.buttonPlacement {
  text-align: center;
  padding-top: 450px;
}
.Button {
  background-color: #212121;
  color: white;
  font-size: 20px;
  padding: 10px 50px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  outline: 0;
  box-shadow: 0px, 2px, 2px, lightgray;
  transition: ease background-color 250ms;
  border-color: #fff;
  border-width: 0.5px;
}
.Button:hover {
  background-color: #000;
}
/* End Button */


/* Start custom Fonts  */
@font-face {
  font-family: "Mosthat";
  src: local("Mosthat"), url("./fonts/Mosthat.otf") format("truetype");
  font-weight: regular;
}


.font-face-Mosthat {
  font-family: "Mosthat";
  font-size: 26px;
}

/* End custom Fonts */



/* Contact Us Page */
.contactTextStyle {
  text-align: center;
  color: white;
}

.formWrapper {
  border-radius: 5px;
  width: 90%;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: 20;
}

.remove-underline {
  text-decoration: none;
}

/* Start Forms */

.labelTxt {
  color: white;
  padding-bottom: 10px;
}

.form-input-bg {
  background-color: #212121;
}

.icons {
  color: #fff;
  margin: 25px;
  font-size: 20px;
}

.iconDiv {
  text-align: center;
}


.form-input {
  text-align: center;
  height: 75px;
  width: auto;
  font-size: 35px;
  line-height: 24px;
}

/* Input fields  */
input[type="email"] {
  color: black;
}

textarea[type="name"] {
  color: black;
}

input[type="text"]:focus {
  color: black;
}
input[type="email"]:focus {
  color: black;
}
textarea[type="text"]:focus {
  color: black;
}

/* End Forms */



.burger_icon {
  display: none;
}



/* SIDE BAR  */
.sidebar_logo {
  position: absolute;
  left: -33px;
  width: 145px;
}
.sidebar_closeIcon {
  right: 25px;
  top: 10px;
  position: absolute;
  font-size: 40px !important;
  cursor: pointer;
  color: red;
}
.sidebar_links_container {
  padding-left: 25px;
  margin-top: 75px;
  padding-bottom: 50px;
}
.sidebar_links {
  text-transform: capitalize;
  color: #FFF;
  font-size: 28px;
  font-family: "Mosthat";
}

.subTxt {
  color: white;
  font-family: "Mosthat";
  font-size: 25px;
}

/* Start Navigation */

nav-bar-bg-color {
  color: #000;
}

nav-bar-spacing {
  margin-right: 10px;
} 

.nav-link {
  letter-spacing: 2.9px;
  font-size: 30px;
  color: red;
  font-weight: 500;
  transition: all 0.4s;
}

.navbar_logo {
  width: 100%;
  height: 50px;
} 

.nav-item {
  display: flex;
  column-gap: 40px;
}

.navigation {
  background-color: #000;
  padding-right: 18px; 
  color: #eff3f7;
  opacity: 0.9;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  width: 100%; 
}

/* End Navigation */


/* Start Youtube container  */
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* End Youtube container  */

/* Start Media Queries */

@media screen and (max-width: 991px) {
  .navlinks_container {
    display: none;
  }

  .site-logo {
    display: none;
  }

  .navbar_logo {
    display: none;
  }

  .navigation {
    background-color: #000;
    padding-right: 18px;
    color: #eff3f7;
    opacity: 0.9;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    width: 100%;
    
  }
  .burger_icon {
    cursor: pointer;
    display: block;
    right: 20px;
    top: 30px;
    color: #eff3f7;
    padding: 10px;
  }
}

@media screen and (max-width: 568px) {
  .navlinks_container {
    display: none;
  }
  .burger_icon {
    cursor: pointer;
    display: block;
    right: 20px;
    top: 30px;
    color: #eff3f7;
    padding: 10px;
  }
}

/* End Media Queries */
